import "./styles.scss"

import React from "react"

const Hero = () => {
  return (
    <section className="about-hero">
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-xl-5 col-lg-6">
            <div className="about-hero__image">
              <img
                src={require("assets/images/about-hero-image.jpg").default}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-6 offset-xl-1 col-lg-6">
            <div className="about-hero__wrapper">
              <div>
                <h2>
                  Jesteśmy DES.
                  <br /> Patrzymy w przyszłość,
                  <br /> nie zapominając o przeszłości
                </h2>
                <div className="about-hero__inner">
                  <p>
                    Od 1981 roku specjalizujemy się w pracach
                    budowlano-konserwatorskich. Za naszym doświadczeniem stoi
                    ponad 360 zrealizowanych z sukcesem projektów.
                  </p>
                  <p>
                    Zakres naszej działalności obejmuje kompleksowe remonty i
                    adaptacje obiektów zabytkowych w pełnym zakresie: od prac
                    konstrukcyjno - budowlanych poprzez prace budowlane
                    zewnętrzne, prace konserwatorskie, po prace związane z
                    adaptacją i wykończeniem wnętrz. Do naszych specjalności
                    należy zaliczyć remonty elewacji tynkowych, remonty elewacji
                    ceglanych, remonty dachów oraz wykonanie izolacji pionowych
                    i poziomych, konserwację budownictwa drewnianego, renowację.
                  </p>
                  <p>
                    Różnorodne umiejętności i specjalizacje, które posiadają
                    zatrudnione osoby sprawiają, że gwarantujemy najwyższy
                    poziom naszych usług.
                  </p>
                </div>
              </div>
              <div className="about-hero__info">
                <img
                  src={require("assets/images/about-hero-logo.png").default}
                  alt=""
                />
                <small>
                  <a
                    href="https://des.krakow.pl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    des.krakow.pl
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
