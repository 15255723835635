import "./styles.scss"

import React from "react"

const History = () => {
  return (
    <section className="about-history">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Historia</h2>
            <div className="about-history__inner">
              <p>
                Firma budowlano – konserwatorska „DES” powstała w 1981 roku przy
                Gminnej Spółdzielni w Michałowicach. Od ponad 30 lat
                specjalizujemy się w pracach budowlano -konserwatorskich w
                województwach Małopolskim i Śląskim. Założyciel firmy Henryk
                Dowgier dba by o zabytki troszczył się zespół wykwalifikowanych
                pracowników.
              </p>
              <p>
                Za zasługi na rzecz ratowania zabytków polskiej kultury,
                założyciel został wyróżniony przez prezydenta RP Orderem
                Odrodzenia Polski.
              </p>
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1  col-md-6">
            <img
              src={require("assets/images/about-history-image.jpg").default}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default History
