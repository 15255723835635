import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import { Hero, Numbers, History } from "page_components/about"

const About = () => (
  <Layout seo={{ title: "O deweloperze" }}>
    <PageHeader title="O deweloperze" />
    <Hero />
    <Numbers />
    <History />
  </Layout>
)

export default About
