import "./styles.scss"

import React from "react"

const Numbers = () => {
  return (
    <section className="about-numbers">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="about-numbers__item">
              <span>385</span>
              <h4>
                zrealizowanych
                <br /> projektów
              </h4>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="about-numbers__item">
              <span>187</span>
              <h4>
                zmodernizowanych <br />
                budynków
              </h4>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="about-numbers__item">
              <span>40</span>
              <h4>
                lat doświadczenia <br />w branży
              </h4>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="about-numbers__item">
              <span>132</span>
              <h4>
                zatrudnionych <br />
                specjalistów
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Numbers
